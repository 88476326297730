<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>General Ledger</h2>
            </CCardHeader>
            <CCardBody>
                <div>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateFrom"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Akhir</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateTo"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>

                    <CRow>
                        <div class="col-12">
                            <button id="view" class="btn btn-success" style="float:right"
                                @click="searchClick()">
                                <i class="fa fa-eye"></i> Search
                            </button>
                        </div>
                    </CRow>
                    <hr>
                    
                    <div class="p-3 border rounded" style="width: 100%;">
                        
                        <div class="col-xs-6 text-light pb-2">
                            <button id="pdf" class="btn btn-secondary"                   
                            @click="pdfClick()">
                                <i class="fa fa-file-pdf-o"></i> PDF
                            </button>&nbsp;
                            <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                                <i class="fa fa-file-excel-o"></i> Excel
                            </button>
                        </div>

                        <datasource ref="generalLedgerDataSource" 
                                    :data="this.GeneralLedgerGridData" 
                                    :page-size="2000" 
                                    :schema-model-fields="this.GeneralLedgerSchemaModel"
                                    :sort="this.Sorting"
                        />

                        <kendo-grid ref="gridItem"
                                    :data-source-ref="'generalLedgerDataSource'"
                                    :pageable="pageableConfig"
                                    :sortable="sortConfig"
                                    :filterable="filterableConfig"
                                    :editable="false"
                                    :resizable="true"
                                    
                        >

                            <kendo-grid-column  :field="'transaction_date'"
                                                :title="'Tanggal'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:center'}"
                                                :template="this.dateFormat"></kendo-grid-column>

                            <kendo-grid-column  :field="'transaction_number'"
                                                :title="'Nomor Bukti'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'contact_name'"
                                                :title="'Kontak'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'chart_of_account_id'"
                                                :title="'Kode Akun'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'chart_of_account_name'"
                                                :title="'Nama Akun'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'desc'"
                                                :title="'Deskripsi'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'debit'"
                                                :title="'Debit'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'credit'"
                                                :title="'Kredit'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'notes'"
                                                :title="'Uraian'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                
                            <kendo-grid-column  :field="'division'"
                                                :title="'Divisi'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>
                            
                            <kendo-grid-column  :field="'budgeting'"
                                                :title="'Budgeting'"
                                                :width = "200"
                                                :format="'{0:N0}'"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                :attributes="{style: 'text-align:right'}"></kendo-grid-column>

                        </kendo-grid>
                    </div>

                    <general-ledger-pdf ref="GeneralLedgerPdf" />
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../infrastructure/constant/response';
import { kendo_grid } from '../../../infrastructure/constant/variable.js';
import GeneralLedgerServices from '../GeneralLedger/Script/GeneralLedgerService';
import GeneralLedgerPdf from '../../../assets/template/GeneralLedgerPdfTemplate.vue';
import { sweet_alert } from '../../../infrastructure/constant/variable.js';

export default {
    name: 'GeneralLedger',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'general-ledger-pdf' : GeneralLedgerPdf
    },
    async mounted () {
        var begin = moment(new Date()).format("YYYY-MM-01");
        var end = moment(new Date()).format("YYYY-MM-") + moment().daysInMonth();

        this.DateFrom= begin;
        this.DateTo= end;
    },
    data(){
        return{
            DateFrom: '',
            DateTo: '',

            //grid
            GeneralLedgerGridData: [],            
            GeneralLedgerSchemaModel: {
                id: { type: "string", editable: false },
                transaction_number: { type: "string", editable: false },
                transaction_date: { type: "date", editable: false },
                contact_id: { type: "string", editable: false },
                contact_name: { type: "string", editable: false },
                chart_of_account_id: { type: "string", editable: false },
                chart_of_account_name: { type: "string", editable: false },
                desc: { type: "string", editable: false },
                credit: { type: "string", editable: false },
                debit: { type: "string", editable: false },
                balance: { type: "string", editable: false },
                notes: { type: "string", editable: false },
                division: { type: "string", editable: false },
                budgeting: { type: "string", editable: false },
            },
            filterableConfig: {
                extra: true,
                operators: kendo_grid.default_operator,
            },
            pageableConfig: kendo_grid.default_pageable_config,
            sortConfig: kendo_grid.multiple_sort_grid,
            Sorting: [
                {field: "transaction_date", dir: "desc"},
                {field: "transaction_number", dir: "desc"},
            ]
        }
    },
    methods: {
        dateFormat(e){
            var date = kendo.toString(kendo.parseDate(e.transaction_date, 'yyyy-MM-dd'), 'yyyy-MM-dd');
            return date == null ? "" : date;
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.DateFrom == null || this.DateFrom == ''){
                this.errorShow('errorDateFrom');
                this.Error++;
            }
            if(this.DateTo == null || this.DateTo == ''){
                this.errorShow('errorDateTo');
                this.Error++;
            }
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
                this.Error++;
            }
        },
        async searchClick(){
            this.inputValidation();

            if(this.Error == 0) {
                const variables = {
                    startDate: this.DateFrom,
                    endDate: this.DateTo
                }
                var data = await GeneralLedgerServices.getAllLedgerData(variables);
                this.GeneralLedgerGridData = data;
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        pdfClick() {
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.GeneralLedgerPdf.generatePDF(this.DateFrom, this.DateTo);
            //     }else if (result.isDenied) {
            this.inputValidation();

            if(this.Error == 0) {
                var startDate = this.DateFrom;
                var endDate = this.DateTo;

                const routeData = this.$router.resolve({name: 'General Ledger Html Template', params: { startdate:startDate, enddate:endDate }});
                window.open(routeData.href, '_blank');
            }
            //     }
            // });
        },
        async exportExcel() {
            const variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo
            }
            // var gridData = await GeneralLedgerServices.getAllLedgerData(variables);
            var gridData = this.$refs.gridItem.kendoWidget()._data;
            var data = GeneralLedgerServices.balanceExcel(gridData, variables);
            
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'BukuBesarGeneral_' + dateNow + '.xlsx';
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = GeneralLedgerServices.balanceExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>