import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class CoaService {
    readCoa(){
        var query = `
            query {
                GetUnnestedChartOfAccounting {
                    id
                    name
                    active_flag
                    head_id
                    coa_type
                    division
                    bank_id
                    currency
                    join_cashflow
                    cashflow_name_plus
                    cashflow_name_min
                    integration_account
                    notes
                    created_at
                    last_update
                }
            }
        `;

        return query;
    }
    
    async addCoa(variables){
        var query = gql`
            mutation( $data: NewChartOfAccounting!) {
                CreateChartOfAccount( New: $data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editCoa(variables){
        var query = gql`
            mutation( $id: String!, $data: NewChartOfAccounting!) {
                UpdateChartOfAccount( ID:$id, New:$data )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async deleteCoa(variables){
        var query = gql`
            mutation( $id: String!) {
                DeleteChartOfAccount( ID:$id )
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getBankData(){
        var query = gql`
            query {
                GetBankList {
                    id
                    bank_name
                    account_name
                    bank_number
                    created_at
                    last_update
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var bankData = [];
        for (let i = 0; i < result.data.GetBankList.length; i++) {
            var label = result.data.GetBankList[i].id + ' (' + result.data.GetBankList[i].bank_name + ')'
            var str = {value:result.data.GetBankList[i].id, label:label}
            bankData.push(str);
        }
        return bankData;
    }

    async getCoaIdData(){
        var query = gql`
            query {
                GetChartOfAccountingIds {
                    id
                    name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetChartOfAccountingIds;
        var CoaIdData = [];
        if (data != null) {            
            for (let i = 0; i < data.length; i++) {
                var str = { value:data[i].id, 
                            label:data[i].name + ' (' + data[i].id + ')'}
                CoaIdData.push(str);
            }
        }
        return CoaIdData;
    }

    async getCurrencyData(){
        var query = gql`
            query {
                GetCurrency {
                    currency_code
                    currency_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var CurrencyData = [];
        for (let i = 0; i < result.data.GetCurrency.length; i++) {
            var label = result.data.GetCurrency[i].currency_code + ' (' + result.data.GetCurrency[i].currency_name + ')'
            var str = {value:result.data.GetCurrency[i].currency_code, label:label}
            CurrencyData.push(str);
        }
        return CurrencyData;
    }

}


export default new CoaService();