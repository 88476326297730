<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="Coa"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import CoaServices from '../Script/CoaService.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'CoaGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    computed: {
        Coa () {
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: CoaServices.readCoa(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetChartOfAccounting == null){
                            return [];
                        }else{
                            return response.data.GetChartOfAccounting;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetChartOfAccounting == null){
                            return 0;
                        }else{
                            return response.data.GetChartOfAccounting.length;
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "id", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "head_id", title: "head_id", headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: "active_flag", title: "Active", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= active_flag ? 'Yes' : 'No' #" },
                { field: "coa_type", title: "Tipe", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "division", title: "Divisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "bank_id", title: "Bank", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "currency", title: "Mata Uang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "join_cashflow", title: "join_cashflow", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= join_cashflow ? 'Yes' : 'No' #" },
                // { field: "cashflow_name_plus", title: "cashflow_name_plus", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "cashflow_name_min", title: "cashflow_name_min", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                // { field: "integration_account", title: "integration_account", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= integration_account ? 'Yes' : 'No' #" },
                // { field: "notes", title: "notes", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var editClick = this.$props.editClick;
        var deleteClick = this.$props.deleteClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            const data = {
                id : dataItem.id,
                name : dataItem.name,
                head_id : dataItem.head_id,
                active_flag : dataItem.active_flag,
                coa_type : dataItem.coa_type,
                division : dataItem.division,
                bank_id : dataItem.bank_id,
                currency : dataItem.currency,
                join_cashflow : dataItem.join_cashflow,
                cashflow_name_plus : dataItem.cashflow_name_plus,
                cashflow_name_min : dataItem.cashflow_name_min,
                integration_account : dataItem.integration_account,
                notes : dataItem.notes
            };

            editClick(data, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            const data = {
                id : dataItem.id,
                name : dataItem.name,
                head_id : dataItem.head_id,
                active_flag : dataItem.active_flag,
                coa_type : dataItem.coa_type,
                division : dataItem.division,
                currency : dataItem.currency,
                join_cashflow : dataItem.join_cashflow,
                cashflow_name_plus : dataItem.cashflow_name_plus,
                cashflow_name_min : dataItem.cashflow_name_min,
                integration_account : dataItem.integration_account,
                notes : dataItem.notes
            };

            editClick(data, true);
        })

        
        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("COA")
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "permissionGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                    { field: "id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "name", title: "Nama", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: "head_id", title: "head_id", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "active_flag", title: "Active", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= active_flag ? 'Yes' : 'No' #" },
                    { field: "coa_type", title: "Tipe", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "division", title: "Divisi", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: "bank_id", title: "Bank", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "currency", title: "Mata Uang", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: "join_cashflow", title: "join_cashflow", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= join_cashflow ? 'Yes' : 'No' #" },
                    // { field: "cashflow_name_plus", title: "cashflow_name_plus", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: "cashflow_name_min", title: "cashflow_name_min", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: "integration_account", title: "integration_account", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: "#= integration_account ? 'Yes' : 'No' #" },
                    // { field: "notes", title: "notes", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                ],
            })
        },
    },
    
}
</script>