<template>
    <div>
        <div class="modal fade" id="CoaModal" tabindex="-1" aria-labelledby="CoaModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="CoaModalLabel" class="font-weight-bold">{{ModalTitle}}{{CoaName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Kode COA</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="CoaId" v-model="CoaId" class="font-weight-bold" />
                                    <label id="errorCoaId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama COA</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="CoaName" v-model="CoaName" class="font-weight-bold" />
                                    <label id="errorCoaName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Kode Induk</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <v-select id="HeadId" class="pb-3" :options="HeadIdData" v-model="HeadId" />
                                    <label id="errorHeadId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Tipe COA</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="CoaType" v-model="CoaType" class="font-weight-bold" />
                                    <label id="errorCoaType" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Divisi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="Division" v-model="Division" class="font-weight-bold" />
                                    <label id="errorDivision" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Bank</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <v-select id="BankId" class="pb-3" :options="BankIdData" v-model="BankId" />
                                    <label id="errorBankId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Mata Uang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="Currency" class="font-weight-bold" :options="CurrencyData" :value.sync="Currency" />
                                    <label id="errorCurrency" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            
                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Catatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="Note" v-model="Note" class="font-weight-bold" />
                                    <label id="errorNote" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Active Flag</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag" />
                                    <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Akun untuk Integrasi</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="IntergrationAccount" class="font-weight-bold" :options="IntergrationAccountData" :value.sync="IntergrationAccount" />
                                    <label id="errorIntergrationAccount" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Gabung Cash flow</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CSelect id="JoinCashflow" class="font-weight-bold" :options="JoinCashflowData" :value.sync="JoinCashflow" />
                                    <label id="errorJoinCashflow" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Nama Cashflow Bertambah</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="CashflowPlus" v-model="CashflowPlus" class="font-weight-bold" />
                                    <label id="errorCashflowPlus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>

                            <CRow>
                                <CCol sm="5">
                                    <label class="form-label font-weight-bold">Nama Cashflow Berkurang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="CashflowMin" v-model="CashflowMin" class="font-weight-bold" />
                                    <label id="errorCashflowMin" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            
                        </div>
                        <br>

                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>
                        <br>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import CoaServices from '../Script/CoaService.js';

export default {
    name: 'CoaForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',
            Id: '',

            CoaId: '',
            CoaName: '',
            CoaType: '',
            Division: '',
            CashflowPlus: '',
            CashflowMin: '',
            Note: '',

            ActiveFlag: '',
            ActiveFlagData: [],
            HeadId: '',
            HeadIdData: [],
            BankId: '',
            BankIdData: [],
            CurrencyData: [],
            Currency: '',
            JoinCashflow: '',
            JoinCashflowData: [],
            IntergrationAccount: '',
            IntergrationAccountData: [],
            
            SaveType: '',
            Error: 0,
            gridDetailReload: 0,
        }
    },
    methods: {
        async addClick(){    
            document.getElementById('CoaId').readOnly = false;
            $(".form-error").css("display", "none");            
            $(".save-button").show();
            this.ModalTitle = 'Add Chart of Accounting: ';
            
            this.CoaId = '';
            this.CoaName = '';
            this.CoaType = '';
            this.Division = '';
            this.CashflowPlus = '';
            this.CashflowMin = '';
            this.Note = '';

            this.ActiveFlagData = [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'}
            ];
            this.ActiveFlag = this.ActiveFlagData[0].value;
            
            this.CurrencyData = await CoaServices.getCurrencyData();
            this.Currency = 'IDR';

            this.HeadIdData = await CoaServices.getCoaIdData();
            this.HeadId = '';

            this.BankIdData = await CoaServices.getBankData();
            this.BankId = '';

            this.JoinCashflowData = [
                {value: true, label: 'Yes'},
                {value: false, label: 'No'}
            ];
            this.JoinCashflow = this.JoinCashflowData[0].value;

            this.IntergrationAccountData = [
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ];
            this.IntergrationAccount = this.IntergrationAccountData[0].value;
            
            this.SaveType = 'Add';            
            window.$('#CoaModal').modal('show');
        },
        async editClick(coaData, View){
            document.getElementById('CoaId').readOnly = true;
            
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Chart of Accounting: ';
            
            this.Id = coaData.id;
            this.CoaId = coaData.id;
            this.CoaName = coaData.name;
            this.CoaType = coaData.coa_type;
            this.Division = coaData.division;
            this.CashflowPlus = coaData.cashflow_name_plus;
            this.CashflowMin = coaData.cashflow_name_min;
            this.Note = coaData.notes;

            this.ActiveFlagData = [
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ];
            this.ActiveFlag = coaData.active_flag;
            
            this.CurrencyData = await CoaServices.getCurrencyData()
            this.Currency = coaData.currency;

            this.HeadIdData = await CoaServices.getCoaIdData();
            this.HeadId = coaData.head_id === null ? '' : this.HeadIdData.find(c => c.value == coaData.head_id);

            this.BankIdData = await CoaServices.getBankData();
            this.BankId = coaData.bank_id === null ? '' : this.BankIdData.find(c => c.value == coaData.bank_id);

            this.JoinCashflowData = [                
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ];
            this.JoinCashflow = coaData.join_cashflow;

            this.IntergrationAccountData = [
                {value: false, label: 'No'},
                {value: true, label: 'Yes'}
            ];
            this.IntergrationAccount = coaData.integration_account;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Chart of Accounting: ';
            }

            window.$('#CoaModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.CoaId == '' || this.CoaId == null){
                this.errorShow('errorCoaId');
            }
            if(this.CoaName == '' || this.CoaName == null){
                this.errorShow('errorCoaName');
            }
        },
        async saveClick() {
            this.inputValidation();

            if(this.Error == 0){

                const CoaData = {
                    id: this.CoaId,
                    name: this.CoaName,
                    active_flag: this.ActiveFlag,
                    head_id: this.HeadId == '' ? null : this.HeadId.value,
                    coa_type: this.CoaType,
                    division: this.Division,
                    bank_id: this.BankId == '' ? null : this.BankId.value,
                    currency: this.Currency,
                    join_cashflow: this.JoinCashflow,
                    cashflow_name_plus: this.CashflowPlus,
                    cashflow_name_min: this.CashflowMin,
                    integration_account: this.IntergrationAccount,
                    notes: this.Note
                }

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : CoaData
                    }
                    
                    CoaServices.addCoa(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#CoaModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : CoaData
                    }
                        
                    CoaServices.editCoa(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#CoaModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
