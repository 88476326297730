<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Trial Balance</h2>
            </CCardHeader>
            <CCardBody>
                <div>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateFrom"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Akhir</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                v-model="DateTo"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Pilih tanggal"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Pengaturan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Setting" class="pb-3" :options="SettingData" v-model="Setting" @input="onChangeSetting()"/>
                            <label id="errorSetting" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>
                    </CRow>
                    <hr>

                    <CRow>
                        <div class="col-6">
                            <button id="addSetting" class="btn btn-primary"
                                @click="addSettingClick()" style="width:150px">
                                <i class="fa fa-plus"></i> Save Setting
                            </button>
                            <label>&nbsp;&nbsp;</label>
                            <button id="deleteSetting" class="btn btn-danger"
                                @click="deleteSettingClick()" style="width:150px">
                                <i class="fa fa-trash"></i> Delete Setting
                            </button>
                        </div>

                        <div class="col-6">
                            <button id="view" class="btn btn-success" style="float:right"
                                @click="viewClick()">
                                <i class="fa fa-eye"></i> View
                            </button>
                        </div>
                    </CRow>
                    <hr>

                    <div class="p-3 border rounded" style="width: 100%;">
                        <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Pengaturan</h4></label>
                        <hr>
                        <datasource ref="settingDataSource" :data="this.SettingGridData" :page-size="10" :schema-model-fields="this.SettingSchemaModel"/>

                        <kendo-grid ref="gridItem"
                                    :data-source-ref="'settingDataSource'"
                                    :pageable="true"
                                    :editable="true"
                                    :filterable="true"
                                    :sortable="true"
                                    :toolbar="['create']"
                                    :resizable="true"
                                    >

                            <kendo-grid-column  :field="'head'"
                                                :title="'Judul'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'coa_group'"
                                                :title="'Grup COA'"
                                                :width = "200"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :field="'coa_id'"
                                                :title="'COA'"
                                                :width = "200"
                                                :editor= "COADropDownEditor"
                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                            <kendo-grid-column  :title="'&nbsp;'"
                                                :attributes="{ class: 'k-text-center' }"
                                                :command="['destroy']"
                                                :width= 200></kendo-grid-column>
                            
                        </kendo-grid>
                    </div>

                    <setting-balance-form ref="settingBalanceForm" :reloadSettingDDL="reloadSettingDDL"/>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import trialBalanceServices from './Script/TrialBalanceServices';
import settingBalanceForm from './Component/SettingBalanceForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'TrialBalance',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'setting-balance-form' : settingBalanceForm,
    },
    async mounted () {
        var begin = moment(new Date()).format("YYYY-MM-01");
        var end = moment(new Date()).format("YYYY-MM-") + moment().daysInMonth();

        this.DateFrom= begin;
        this.DateTo= end;
        this.SettingData= await trialBalanceServices.getBalanceSettingQuery();
        this.Setting= '';
        this.COAData= await trialBalanceServices.getCOAQuery();

        document.getElementById("deleteSetting").disabled = true;
    },
    data(){
        return{
            DateFrom: '',
            DateTo: '',
            SettingData: [],
            Setting: '',
            COAData: [],

            //grid
            SettingGridData: [],
            SettingSchemaModel: {
                head: { type: "string", editable: true, validation: {required: true} },
                coa_group: { type: "string", editable: true, validation: {required: true} },
                coa_id: { type: "string", editable: true, validation: {required: true} },
            },
        }
    },
    methods: {
        COADropDownEditor(container, options) {
            var vue = this;
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: false,
                    dataTextField: "label",
                    dataValueField: "value",
                    filter: "contains",
                    dataSource: {
                        data: vue.COAData
                    }
                });
        },
        async onChangeSetting(){
            if(this.Setting == null) {
                this.SettingGridData = [];
                document.getElementById("deleteSetting").disabled = true;
            }else{
                var data = await trialBalanceServices.getBalanceSettingData(this.Setting.value);
                this.SettingGridData = data;
                document.getElementById("deleteSetting").disabled = false;
            }
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.DateFrom == null || this.DateFrom == ''){
                this.errorShow('errorDateFrom');
                this.Error++;
            }
            if(this.DateTo == null || this.DateTo == ''){
                this.errorShow('errorDateTo');
                this.Error++;
            }
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
                this.Error++;
            }

            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            if(gridData.length <= 0){
                this.$swal("Error", "Pengaturan harus diisi terlebih dahulu", "error");
                this.Error++;
            }
        },
        viewClick(){
            this.inputValidation();

            if(this.Error == 0){
                this.$router.push({ name: 'Trial Balance Grid', params: { datefrom: this.DateFrom, dateto: this.DateTo, settingid: this.Setting.value } });
            }
        },
        addSettingClick(){
            var gridData = this.$refs.gridItem.kendoWidget().dataSource._data;
            var objData = this.$globalfunc.objectToArrayConverter(gridData, "Accounting-SettingBalance");

            if(gridData.length <= 0){
                this.$swal("Error", "Pengaturan harus diisi terlebih dahulu sebelum disimpan", "error");
            }else{
                this.$refs.settingBalanceForm.addClick(objData);
            }
        },
        deleteSettingClick(){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : this.Setting.value
                }
                if (result.isConfirmed == true) {
                    trialBalanceServices.deleteSetting(variables).then(res =>{
                        this.reloadSettingDDL().then(res =>{
                            this.$swal("Info", response.successDelete, "success");
                            this.SettingGridData = [];
                            this.Setting = '';
                        });
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        async reloadSettingDDL(){
            this.SettingData= await trialBalanceServices.getBalanceSettingQuery();
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>